<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :icon="icon"
    :allow-auto-height="true"
    :errors="errors"
    :is-active="isActive"
    :is-loading="isLoading"
    :is-disabled="isDisabled"
    :disable-disabled-opacity="disableDisabledOpacity"
    :has-value="!!text"
    @click="() => input?.focus()"
    @keydown.enter.exact.prevent="() => input?.focus()"
    @focus="
      () => {
        if (activateOnFocus) {
          input?.focus();
        }
      }
    "
  >
    <div class="input-multiline-text" v-class-mod:input-multiline-text="{ isActive, canEdit: !isDisabled }">
      <span
        :contenteditable="!isDisabled"
        :tabindex="-1"
        :style="`--min-height: calc(var(--font-size) * var(--line-height) * ${defaultLines})`"
        class="input-multiline-text__input"
        @blur="
          () => {
            if (!isUpdatingRawValue && input && !isDisabled && input.innerText !== props.text) {
              $emit('update', input.innerText);
            }
            isActive = false;
          }
        "
        @focus="
          () => {
            if (!isDisabled) {
              isActive = true;
            }
          }
        "
        @keyup="
          () => {
            if (input && !isDisabled && input.innerText !== props.text) {
              $emit('update', input.innerText);
            }
          }
        "
        @keydown.enter.exact.prevent.stop="
          () => {
            input?.blur();
            $emit('enter');
          }
        "
        @keydown.esc.stop="
          () => {
            $emit('escape');
            input?.blur();
          }
        "
        :ref="
          (r) =>
            refHandler(r, (r) => {
              input = r;
              updateRawValue();
            })
        "
      />
      <span v-if="!isActive || !text" class="input-multiline-text__placeholder" @click="() => input?.focus()">
        {{ placeholder }}
      </span>
    </div>
  </base-label>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

import { delayFn, isHtmlElem, refHandler } from "@horizon56/utils";

import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";

const props = withDefaults(
  defineProps<
    PublicProps & {
      text?: string;
      placeholder?: string;
      autofocus?: boolean;
      activateOnFocus?: boolean;
      defaultLines?: number;
    }
  >(),
  {
    activateOnFocus: true,
    defaultLines: 3.7,
  },
);

const emit = defineEmits<{
  (e: "update", a: string): void;
  (e: "enter"): void;
  (e: "escape"): void;
  (e: "is-active", active: boolean): void;
}>();

const input = ref<HTMLElement | null>();
const isActive = ref(false);
const isUpdatingRawValue = ref(false);

const updateRawValue = () => {
  if (!isActive.value && input.value && props.text !== input.value.innerText) {
    isUpdatingRawValue.value = true;
    input.value.innerText = props.text ?? "";
    isUpdatingRawValue.value = true;
  }
};

watch(
  () => isActive.value,
  (is) => {
    emit("is-active", is);
    if (!is) {
      updateRawValue();
    }
  },
);

watch(() => props.text, updateRawValue);

watch(
  () => props.autofocus,
  async () => {
    if (props.autofocus) {
      if (!isHtmlElem(input.value)) {
        await delayFn(() => isHtmlElem(input.value));
      }
      input.value?.focus();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.input-multiline-text {
  --line-height: 1.5;
  --font-size: var(--app-font-size-base);
  position: relative;
  width: 100%;
  line-height: 1.14;
  word-break: break-word;
  display: inline-flex;
  align-items: center;
  margin: calc((var(--input-min-height) - (var(--app-font-size-base) * 1.24)) / 2) 0;
  &:not(#{&}--isActive) #{&} {
    &__placeholder {
      &::after {
        content: "";
        position: absolute;
        @include position-corner;
      }
    }
  }
  &__input {
    width: 100%;
    color: var(--black-50);
    display: inline-block;
    margin-left: var(--input-spacing, 0px);
    min-width: 2px;
    outline: none;
    min-height: var(--min-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  &--canEdit #{&}__input {
    color: var(--black-90);
    cursor: text;
  }

  &__placeholder {
    color: var(--black-50);
    cursor: pointer;
    position: absolute;
    white-space: nowrap;
    top: 0;
    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  &__input:not(:empty) + #{&}__placeholder {
    display: none;
  }
}
</style>
